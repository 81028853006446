<template>
    <span class="">
        <CustomIcon v-if="!isListening" @click="listen(channelName)" toolTip="Enable Notification" glyph="mdi-bell-outline" /> 
        <CustomIcon v-if="isListening" @click="unlisten(channelName)" toolTip="Disable Notification" glyph="mdi-bell-ring" /> 
    </span>
</template>
<script>
import api from '/src/utils/api';

export default {
    components: {
        CustomIcon: () => import("/src/components/CustomIcon.vue"),
    },
    data() {
        return {
            isListening: false,
        };
    },
    mounted() {
        this.refreshChannel();
    },
    methods: {
        refreshChannel() {
            api.getCall(`get-channels?channel=${this.channelName}`).then(channels => {
                this.isListening = channels[0].is_listening;
            });
        },
        listen(channelName) {
            api.postAuthRequired('listen-to-channel', {channelName}).then(() => {
                this.refreshChannel();
                this.refreshAllFriendData();
            });
        },
        unlisten(channelName) {
            api.postAuthRequired('unlisten-to-channel', {channelName}).then(() => {
                this.refreshChannel();
                this.refreshAllFriendData();
            });
        }
    },
    props: ['channelName','refreshAllFriendData'],
};
</script>
<style scoped>
</style>